<template>
    <div
        :class="{ 'border-container-hover': isDragging }"
        class="border-container"
        @click="selectFile"
        @dragover.prevent="dragOver"
        @dragleave.prevent="dragLeave"
        @drop.prevent="dropFile"
    >

        <div v-if="!file" class="select-file-container">
            <img alt="icon" class="icon" src="@/assets/images/image-upload/icon.png">
            <span class="select-file">Select File</span>
        </div>
        <div v-else-if="isLoading" class="loader" />
        <div v-else class="file-name">
            <span>{{ file.name }}</span>
            <font-awesome-icon v-if="isSuccess" :icon="['fas','check']" class="name-icon" />
            <font-awesome-icon v-else :icon="['fas', 'times']" class="decline" />
            <span v-if="!isSuccess" class="error-message">
                {{ errorMessage }}
            </span>
        </div>
        <input
            ref="fileInput"
            accept="image/jpeg,image/png,application/pdf"
            class="d-none"
            type="file"
            @change="handleFileSelect"
        >

    </div>

</template>
<script>
    import uploadService from '@/services/upload-service'

    export default {
        name: 'BoardingPassUpload',
        data() {
            return {
                file: null,
                isLoading: false,
                isSuccess: false,
                isDragging: false,
                errorMessage: null
            }
        },
        methods: {
            async uploadFile() {
                if (!this.file) {
                    return
                }
                this.isLoading = true
                try {
                    await uploadService.uploadFile(
                        'boarding-passes/search-by-bp-file',
                        this.file
                    )
                    this.isSuccess = true

                    this.$router.push({ name: 'My Flights' })
                } catch (error) {
                    this.handleErrors(error)
                } finally {
                    this.isLoading = false
                }
            },
            dragOver(event) {
                this.isDragging = true
            },
            dragLeave(event) {
                this.isDragging = false
            },
            dropFile(event) {
                this.isDragging = false
                const files = event.dataTransfer.files
                this.file = files[0]
                this.uploadFile()
            },
            selectFile() {
                this.$refs.fileInput.click()
            },
            handleFileSelect(event) {
                this.file = event.target.files[0]
                this.uploadFile()
            },
            handleErrors(error) {
                this.isSuccess = false
                const errorCode = error.response?.data?.code
                if (errorCode === 'NO_BOARDING_PASS_FOUND') {
                    this.errorMessage = this.$t('myFlights.boardingPassNotFoundError')
                }
                // TODO: BOARDING_PASS_IS_EXPIRED
            }

        }

    }
</script>

<style scoped>
.border-container {
    max-width: 272px;
    width: 100%;
    min-height: 114px;
    background-color: #0c2949;
    border: 2px solid #0af;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
    transition: border-color 0.3s;
    padding: 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7rem;
}

.border-container-hover {
    border-color: #247ddc;
}

.select-file-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7rem;
}

.icon img {
    max-width: 35px;
}

.select-file {
    font-weight: 300;
    font-size: 0.7rem;
}

.file-name {
    display: flex;
    gap: 1rem;
    align-items: center;
    height: 100%;
}

.name-icon {
    color: #0af;
}

.decline {
    color: #f00;
}

.error-message {
    font-size: 0.8rem;
    font-weight: 300;
    color: #f00;
}

.loader {
    width: 25px;
    height: 25px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 4px solid #0af;
    animation:
        l20-1 0.8s infinite linear alternate,
        l20-2 1.6s infinite linear;
}

@keyframes l20-1 {
    0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }

    12.5% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    62.5% {
        clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    75% {
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }

    100% {
        clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
}

@keyframes l20-2 {
    0% {
        transform: scaleY(1) rotate(0deg);
    }

    49.99% {
        transform: scaleY(1) rotate(135deg);
    }

    50% {
        transform: scaleY(-1) rotate(0deg);
    }

    100% {
        transform: scaleY(-1) rotate(-135deg);
    }
}

@media screen and (min-width: 992px) {
    .border-container {
        max-width: 400px;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        justify-content: center;
    }

    .loader {
        width: 40px;
        height: 40px;
        border: 6px solid #0af;
    }
}

</style>
