import axios from 'axios'
import jwtUtils from '@/utils/jwt-utils'
import store from '@/store'

const API_URL = process.env.VUE_APP_API_HOST_DOMAIN + process.env.VUE_APP_API_BASE_PATH
const defaultLanguage = process.env.VUE_APP_DEFAULT_LANGUAGE || 'EN'
const apiToken = localStorage.getItem(`${process.env.VUE_APP_API_HOST_DOMAIN}-web`)

let headers = {
    'Content-Type': 'multipart/form-data',
    'lang': defaultLanguage
}

if (apiToken) {
    if (!jwtUtils.isValid(apiToken)) {
        store.dispatch('logout')
        location.reload()
    }
    headers = {
        ...headers,
        'Authorization': 'Bearer ' + apiToken
    }
}

export default {
    uploadFile(url, file) {
        const formData = new FormData()
        const newFileName = this.checkFileNameAndFix(file)

        formData.append('file', file, newFileName)

        return new Promise((resolve, reject) => {
            return axios({
                url: API_URL + '/' + url,
                method: 'post',
                headers,
                data: formData
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                }
                )
        }
        )
    },
    uploadFileWithProgress(urlPath, file, onUploadProgress) {
        const formData = new FormData()
        const newFileName = this.checkFileNameAndFix(file)
        formData.append('file', file, newFileName)
        return new Promise((resolve, reject) => {
            return axios({
                url: API_URL + '/' + urlPath,
                method: 'post',
                headers,
                data: formData,
                onUploadProgress: (progressEvent) => {
                    onUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                }
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        }
        )
    },
    checkFileNameAndFix(file) {
        const newFileName = file?.name
        const illegalChars = /[\s$%?!()#]/g
        return newFileName?.replace(illegalChars, '_')
    }
}
