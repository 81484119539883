<template>
    <div class="camera-container">
        <qrcode-stream
            v-if="result.length === 0"
            :constraints="{ facingMode: 'environment' }"
            :track="paintBoundingBox"
            :formats="selectedBarcodeFormats"
            @detect="onDetect"
            @error="onError"
            @camera-on="onCameraReady"
        />
    </div>
</template>

<script>
    import {
        QrcodeStream
    } from 'vue-qrcode-reader'

    export default {
        components: {
            QrcodeStream
        },
        data() {
            return {
                barcodeFormats: {
                    aztec: true,
                    code_128: false,
                    code_39: false,
                    code_93: false,
                    codabar: false,
                    databar: false,
                    databar_expanded: false,
                    data_matrix: false,
                    dx_film_edge: false,
                    ean_13: false,
                    ean_8: false,
                    itf: false,
                    maxi_code: false,
                    micro_qr_code: false,
                    pdf417: true,
                    qr_code: false,
                    rm_qr_code: false,
                    upc_a: false,
                    upc_e: false,
                    linear_codes: false,
                    matrix_codes: false
                },
                result: ''
            }
        },
        computed: {
            selectedBarcodeFormats() {
                return Object.keys(this.barcodeFormats).filter(
                    format => this.barcodeFormats[format]
                )
            }
        },
        methods: {
            async onDetect(promise) {
                try {
                    const { content } = await promise
                    this.$emit('close-scanner', content)
                } catch (error) {
                    this.displayToast(error)
                }
            },
            onError(err) {
                this.error = `[${err.name}]: `

                if (err.name === 'NotAllowedError') {
                    this.error += 'you need to grant camera access permission'
                } else if (err.name === 'NotFoundError') {
                    this.error += 'no camera on this device'
                } else if (err.name === 'NotSupportedError') {
                    this.error += 'secure context required (HTTPS, localhost)'
                } else if (err.name === 'NotReadableError') {
                    this.error += 'is the camera already in use?'
                } else if (err.name === 'OverconstrainedError') {
                    this.error += 'installed cameras are not suitable'
                } else if (err.name === 'StreamApiNotSupportedError') {
                    this.error += 'Stream API is not supported in this browser'
                } else if (err.name === 'InsecureContextError') {
                    this.error +=
                        'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
                } else {
                    this.error += err.message
                }
                this.displayToast(this.error)
            },
            async onCameraReady() {
                this.error = ''
            },
            paintBoundingBox(detectedCodes, ctx) {
                for (const detectedCode of detectedCodes) {
                    const {
                        boundingBox: { x, y, width, height }
                    } = detectedCode

                    ctx.lineWidth = 2
                    ctx.strokeStyle = '#007bff'
                    ctx.strokeRect(x, y, width, height)
                }
            }
        }
    }
</script>

<style scoped>
.error {
    font-weight: bold;
    color: red;
}

.barcode-format-checkbox {
    margin-right: 10px;
    white-space: nowrap;
    display: inline-block;
}
</style>
